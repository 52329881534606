









































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Dialog from '@/components/Dialog/index.vue'
import { getTemplateExcel } from '@/utils/export'
import { Upload } from 'element-ui'
Vue.use(Upload)

@Component({
  components: {
    Dialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private isShow!: boolean
  @Prop({ default: false }) private loading!: boolean

  currentBtn = ''
  uploadParams = {
    template: '房屋导入模板',
    excel: null
  }

  @Watch('currentBtn')
  getcurrentBtn(val: string) {
    // 下载导入模板
    if (val === 'download') {
      this.getModalExcel()
    }
    // 手动新增
    if (this.currentBtn === 'add') {
      this.$router.push('/residents/home-edit')
      this.$emit('optSuccess')
    }
  }

  // 获取导入模板
  async getModalExcel() {
    getTemplateExcel('房屋导入模板', '批量新增房屋.xlsx')
  }

  // 上传成功
  uploadSuccess(res: any) {
    if (res.success) {
      const p = res.data
      this.$api.resident.addHouse(p).then((res: any) => {
        const data = res.data
        if (data.success) {
          this.$message.success('批量导入成功')
          this.$emit('optSuccess', 'import')
        } else {
          this.$message.error(data.msg)
        }
      })
    } else {
      this.$message.error(res.msg)
    }
  }

  // 上传前
  beforeUpload(file: any) {
    this.uploadParams.excel = file
    const fname = file.name.substr(file.name.lastIndexOf('.')).toLowerCase()
    if (fname !== '.xls' && fname !== '.xlsx' && fname !== '.xlsm') {
      this.$message.error('请必须上传.xls、.xlsx后缀的excel文件')
      return false
    }
    const size = file.size / 1024 / 1024
    if (size > 5) {
      this.$message.error('请必须上传小于5M的文件')
      return false
    }
    return true
  }

  closeDialog() {
    this.$emit('close', '')
  }
  dialogConfirm() {
    this.$emit('optSuccess')
  }
}
