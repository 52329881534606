
































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop } from 'vue-property-decorator'
import Dialog from '@/components/Dialog/index.vue'
import { Checkbox, CheckboxGroup } from 'element-ui'
import { CommonModule } from '@/store/modules/common'
Vue.use(Checkbox)
Vue.use(CheckboxGroup)

@Component({
  components: {
    Dialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private isShow!: boolean
  @Prop({ default: false }) private loading!: boolean

  checkAll = false
  doorsData: any = [
    {
      label: '东南门',
      value: '1'
    },
    {
      label: '北门',
      value: '2'
    },
    {
      label: '西门',
      value: '3'
    },
    {
      label: '西北门',
      value: '4'
    }
  ]
  // 获取设备组数据
  get deviceGroups() {
    return CommonModule.deviceGroups
  }
  async beforeCreate() {
    CommonModule.GetDeviceGroups()
  }
  checkedList = [] as any

  handleCheckAllChange(val: boolean) {
    this.checkedList = val
      ? this.deviceGroups.map((v: any) => {
          return v.id
        })
      : []
  }

  handleCheckedDoorsChange(value: any) {
    const checkedCount = value.length
    this.checkAll = checkedCount === this.deviceGroups.length
  }

  closeDialog() {
    this.$emit('close', '')
  }
  dialogConfirm() {
    if (this.checkedList.length === 0) {
      this.$message.warning('请先选择门禁')
    } else {
      this.$emit('optSuccess', this.checkedList)
    }
  }
}
