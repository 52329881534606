







































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import AddHomeModal from './addHome-modal.vue'
import AddDoorModal from './addDoor-modal.vue'
import { CommonModule } from '@/store/modules/common'
import { getDicts, getDictName } from '@/utils/dict'
import { Select, Option } from 'element-ui'
Vue.use(Select)
Vue.use(Option)

@Component({
  components: {
    Table,
    Pagination,
    ImgView,
    AddHomeModal,
    AddDoorModal
  }
})
export default class HomeList extends Vue {
  $messagebox: any
  $constants: any
  // 字典数据
  // 房屋类型
  houseTypeDicts: any = []
  tabActive = ''
  listLoading = false
  // 是否有表格勾选
  tableChoose = false
  tableHeader = [
    {
      key: 'phase',
      name: '区域',
      width: '8%'
    },
    {
      key: 'buildingNo',
      name: '房屋',
      width: '6%'
    },
    {
      key: 'certImg',
      name: '房本照片',
      width: '8%',
      isSpecial: true
    },
    {
      key: 'ownerName',
      name: '业主',
      width: '8%'
    },
    {
      key: 'mobile',
      name: '联系方式',
      width: ''
    },
    {
      key: 'houseType',
      name: '房屋类型',
      width: '8%',
      isSpecial: true
    },
    {
      key: 'personNum',
      name: '人员',
      width: '7%',
      isSpecial: true
    },
    {
      key: 'repairTimes',
      name: '报修',
      width: '7%',
      isSpecial: true
    },
    {
      key: 'permitStatus',
      name: '是否设置权限',
      width: '8%',
      isSpecial: true
    },
    {
      key: 'remark',
      name: '备注',
      width: '10%',
      remark: true
    },
    {
      key: 'opt',
      name: '操作',
      width: '10%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  permitStatus = ''
  searchModel = {
    key: 'buildingNo',
    value: null
  }
  currentHoverBtn = ''
  // 批量操作 按钮 显隐控制
  batchOpt = false
  checkedList: any = []
  // 当前显示哪个弹窗
  currentOpenDialog = ''
  imgUrl = ''

  // 获取房屋期数
  get houseAreas() {
    return CommonModule.houseAreas
  }

  // 监听器
  // @Watch('houseAreas')
  // gethouseAreas() {
  //   this.tabActive = this.houseAreas[0].phase
  // }

  @Watch('tabActive')
  gettabActive() {
    this.getHomeList()
  }

  async beforeCreate() {
    CommonModule.GetHouseAreas()
    // 房屋类型
    this.houseTypeDicts = await getDicts('HOUSE_TYPE')
  }

  mounted() {
    this.getHomeList()
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  tabAll() {
    this.tabActive = ''
  }

  tabChange(name: string) {
    this.listQuery.page = 1
    this.listQuery.limit = 15
    this.searchModel = {
      key: 'ownerName',
      value: null
    }
    this.tabActive = name
  }

  // btn移入移出控制样式
  hoverbtn(kind: string) {
    this.currentHoverBtn = kind
  }
  defaultbtn() {
    this.currentHoverBtn = ''
  }

  // 点击了哪个批量操作按钮（门禁/消息）
  clickOpt(kind: string) {
    const houseIds: any = []
    if (kind === 'door') {
      this.openDialog('adddoor')
    } else if (kind === 'news') {
      // 选中的房屋
      this.checkedList.forEach((item: any) => {
        houseIds.push({
          id: item.id,
          phase: item.phase,
          buildingNo: item.buildingNo
        })
      })
      this.$router.push('/property/news?houseIds=' + JSON.stringify(houseIds))
    }
  }
  //查询
  searchClick() {
    this.listQuery.page = 1
    this.listQuery.limit = 15
    this.getHomeList()
  }
  // 批量增加门禁
  AddDoor(doorChecked: any) {
    const houseIds = [] as any
    if (this.checkedList.length === 0) {
      return this.$message.warning('请先选择房屋')
    }
    // 选中的房屋
    this.checkedList.forEach((item: any) => {
      houseIds.push(item.id)
    })
    this.listLoading = true
    this.$api.resident
      .addDeviceAuths({
        deviceGroupIds: doorChecked,
        houseIds: houseIds
      })
      .then((res: any) => {
        this.listLoading = false
        if (res.data.success) {
          this.$message.success(res.data.msg)
          this.closeDialog()
          this.getHomeList()
        } else {
          this.$message.error(res.data.msg)
        }
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 获取房屋列表
  getHomeList() {
    this.listLoading = true
    this.$api.resident
      .getHouseList({
        permitStatus: this.permitStatus,
        current: this.listQuery.page,
        size: this.listQuery.limit,
        phase: this.tabActive,
        [this.searchModel.key]: this.searchModel.value
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        this.tableData = data.data.map((v: any) => {
          v['isChecked'] = false
          return v
        })
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 搜索
  // search() {
  //   if (this.searchModel.value) {
  //     this.getHomeList()
  //   } else {
  //     this.$message.warning('请先输入搜索内容')
  //   }
  // }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }

  // 打开推送
  openPush(item: any) {
    const house = [
      {
        id: item.id,
        phase: item.phase,
        buildingNo: item.buildingNo
      }
    ]
    this.$router.push('/property/news?houseIds=' + JSON.stringify(house))
  }

  // 打开编辑
  openEdit(item: any) {
    this.$router.push({
      path: '/residents/home-edit',
      query: {
        id: item.id
      }
    })
  }

  // 详情
  openDetail(item: any) {
    this.$router.push({
      path: '/residents/detail',
      query: {
        id: item.id
      }
    })
  }

  // 获取当前已选
  getCheckedList(list: any) {
    this.checkedList = list
  }

  // 对话框
  // 打开对话框
  async openDialog(obj: string) {
    this.currentOpenDialog = obj
  }

  // 关闭对话框
  closeDialog() {
    this.currentOpenDialog = ''
  }
  // 操作对话框后统一回调
  afterOpt(data?: any) {
    if (data === 'import') {
      this.$messagebox(
        '批量导入的房屋还未添加房屋权限请尽快添加！',
        '友情提醒',
        {
          confirmButtonText: '确定',
          callback: () => {
            this.getHomeList()
          }
        }
      )
    }
    this.closeDialog()
  }
}
